import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/chatbot.scss";
import ChatData from "./ChatData";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import remarkGfm from "remark-gfm";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

const ChatBot = () => {
  const [isChatBoxOpen, setChatBoxOpen] = useState(false);
  const [userInputContent, setUserInputContent] = useState("");
  const [messages, setMessages] = useState([]);
  const [pendingMessage, setPendingMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const chatBodyRef = useRef(null);

  const handleClose = () => {
    setChatBoxOpen(false);
  };

  const handleOpen = () => {
    setChatBoxOpen(true);
  };

  const handleUserInput = async (content) => {
    if (isLoading || content === "" || content === null || content === undefined) {
      return;
    } else {
      const data = new ChatData("user", content, false);
      setMessages((prevMessages) => [...prevMessages, data]);

      setUserInputContent("");
      setLoading(true);
      let localIsLoading = true;

      const transformedJsonArray = messages.map((dataObj) => ({
        role: dataObj.type,
        content: dataObj.content,
      }));
      transformedJsonArray.push({
        role: "user",
        content: content,
      });

      const messageData = JSON.stringify([...transformedJsonArray]);

      const controller = new AbortController();
      const signal = controller.signal;

      // Using the fetch API to send data
      const response = await fetch("https://app.mirada.ai/api/mirada/chatbox", {
        method: "POST",
        headers: {
          "Content-Type": "text/event-stream",
        },
        body: messageData,
        signal: signal,
      }).catch((error) => {
        console.error("Error:", error);
      });
      try {
        const reader = response?.body?.pipeThrough(new TextDecoderStream()).getReader();
        if (reader == null) return;
        let accumulatedData = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            const data = new ChatData("assistant", `${accumulatedData}`, false);
            setMessages((prevMessages) => [...prevMessages, data]);
            setPendingMessage("");
            setLoading(false);
            localIsLoading = false;
            break;
          }
          if (value) {
            if (!localIsLoading) {
              const data = new ChatData("assistant", `${accumulatedData}`, false);
              setMessages((prevMessages) => [...prevMessages, data]);
              setPendingMessage("");

              controller.abort();
              break;
            }
            accumulatedData += value;
            setPendingMessage((prevText) => prevText + value);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleInput = (event) => {
    setUserInputContent(event?.target?.value);
  };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages, pendingMessage]);

  return (
    <>
      <div className={`chat-box ${isChatBoxOpen ? "visible" : "hidden"}`}>
        <div className="chat-box-header">
          <h3>Advanced AI Chat</h3>
          <p onClick={handleClose}>
            <i className="fa fa-times"></i>
          </p>
        </div>
        <div className="chat-box-body" ref={chatBodyRef}>
          <div className="chat-box-body-receive">
            <p>
              To experience full AI capabilities visit our web platform{" "}
              <a href="https://mirada.ai/launch" target="_blank" rel="noreferrer">
                here
              </a>
              .
            </p>
            <span>System</span>
          </div>
          <div className="chat-box-body-receive">
            <p>
              Get Mirada AI for your project &amp; reach the team{" "}
              <a href="mailto:hi@mirada.ai" target="_blank" rel="noreferrer">
                here
              </a>
              .
            </p>

            <span>System</span>
          </div>

          <div className="chat-box-body-receive">
            <div className="chat-icon-system" />
            <p style={{ fontWeight: 600 }}>Ask me for project links, about our tokenomics, anything you need!</p>
          </div>

          {messages?.map((item, index) => {
            if (item.type === "user") {
              return (
                <div id={`item${index}`} className="chat-box-body-send">
                  <p>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{item?.content}</ReactMarkdown>
                  </p>
                </div>
              );
            } else {
              return (
                <div id={`item${index}`} className="chat-box-body-receive">
                  <div className="chat-icon-system" />
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{item?.content}</ReactMarkdown>
                </div>
              );
            }
          })}
          {pendingMessage && (
            <div className="chat-box-body-receive">
              <div className="chat-icon-system" />
              <p>{pendingMessage}</p>
            </div>
          )}
        </div>
        <div className="chat-box-footer">
          <input
            value={userInputContent}
            onInput={handleInput}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                handleUserInput(userInputContent);
              }
            }}
            placeholder="Enter Your Message"
            type="text"
          />
          <i onClick={() => handleUserInput(userInputContent)} className="send far fa-paper-plane"></i>
        </div>
      </div>

      <div className="chat-button" style={{ display: isChatBoxOpen ? "none" : "block" }} onClick={handleOpen}>
        <span>Chat with Mirada AI</span>
      </div>

      {/* BACKGROUND SHADE */}
      {/* <div className={`chatmodal ${isChatBoxOpen ? "show-chatmodal" : ""}`}/> */}
    </>
  );
};

export default ChatBot;
